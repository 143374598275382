import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('share_overlay', () => ({
    visible: false,
    copied: false,
    title: '',
    url: '',
    init () {
        // console.log('share init');
    },
    async onOpenShareOverlay (evt) {
        if (evt.detail && evt.detail.url && evt.detail.url != '') this.url = evt.detail.url;
        if (evt.detail && evt.detail.title && evt.detail.title != '') this.title = evt.detail.title;

        if (window.innerWidth <= 768) {
            if (navigator.canShare) {
                try {
                    await navigator.share({ text: this.title, url: this.url });
                } catch (error) {
                    if (error && !error.message.contains('AbortError')) alert(`Fejl i deling: ${ error }`);
                }
            } else {
                this.open();
            }
        } else {
            this.open();
        }
    },
    open () {
        this.visible = true;
    },
    close () {
        this.visible = false;
    },
    share_facebook () {
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${ this.url }`);
    },
    share_bluesky () {
        window.open(`https://bsky.app/intent/compose?text=${ encodeURIComponent(this.title) } - ${ encodeURIComponent(this.url) }`)
    },
    share_linkedin () {
        window.open(`https://www.linkedin.com/shareArticle?url=${ this.url }&title=${ this.title }&source=TV MIDTVEST`);
    },
    share_x () {
        window.open(`https://twitter.com/share?url=${ this.url }&text=${ this.title }`);
    },
    share_mastodon () {
        window.open(`https://mastodonshare.com/?text=${ this.title }&url=${ this.url }`);
    },
    share_email () {
        window.open(`mailto:?subject=${ this.title }&body=${ this.url }`);
    },
    share_copy () {
        navigator.clipboard.writeText(this.url);
        this.copied = true;
        setTimeout(() => {
            this.copied = false
        }, 3000);
    }
}));
