const timeout = 30000; // 30 seconds
const interval = 200; // five times per second
const startTime = Date.now();

const libs = {
  'plausible' : null,
  'Typesense': null,
};

/*

// Possibly a better solution? Needs more testing ...

Object.defineProperty(window, key, {
  enumerable: false,
  get () {
    return value;
  },
  set (v) {
    value = v;
    // resolve the promise here
  }
});

*/

for (const key of Object.keys(libs)) {
  libs[key] = new Promise((resolve, reject) => {
    if (window[key]) {
      resolve(window[key]);
    } else {
      let timer = setInterval(() => {
        if (Date.now() - startTime > timeout) {
          clearInterval(timer);
          reject(new Error('Timeout'));
        }
        if (window[key]) {
          clearInterval(timer);
          resolve(window[key]);
        }
      }, interval);
    }
  });
}

export default libs;
