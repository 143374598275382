import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";
import libs from "../frop/libs.js";

Alpine.data('TypesenseSearch', ({query, config}) => ({
  query: query || '',
  lastExecutedQuery: '',
  config: Object.assign({
    collection: 'public2025',
    type_filters: {
      articles: 'Artikler',
      episodes: 'Tv-programmer',
      news_broadcasts: 'Nyhedsudsendelser',
      series: 'Serier',
    },
    highlight_full_fields: ['content'],
  }, config),
  results: [],
  loading: false,
  error: false,
  filters: [],
  searched: false,
  currentPage: 1,
  totalPages: 0,
  totalFound: 0,
  perPage: 10,
  types: {
    articles: 'Artikel',
    episodes: 'Tv-program',
    series: 'Tv serie',
    news_broadcasts: 'Nyhedsudsendelse',
  },
  client: null,
  init() {
    libs.Typesense.then(Typesense => {
      this.client = new Typesense.Client({
        nodes: [{
          host: this.config.host,
          port: 443,
          protocol: 'https'
        }],
        apiKey: this.config.api_key,
      });
      this.search();
    });
  },
  search() {
    if (!this.client || !this.query.trim()) return;
    this.results = [];
    if ('URLSearchParams' in window) {
      const url = new URL(window.location);
      url.searchParams.set("q", this.query);
      history.pushState(null, '', url);
    }
    this.performSearch(1);
  },
  performSearch(page) {
    this.loading = true;
    this.error = false;
    this.currentPage = page;
    this.lastExecutedQuery = this.query;
    this.client.collections(this.config.collection).documents().search(this.buildQuery({'page': page}))
    .then(response => {
      this.results = this.results.concat(response.hits);
      this.totalFound = response.found;
      this.totalPages = Math.ceil(response.found / this.perPage);
      this.searched = true;
      this.loading = false;
    })
    .catch(err => {
      console.error('Search error:', err);
      this.error = true;
      this.loading = false;
    });
  },
  buildQuery(opts) {
    const query = Object.assign({}, opts, {
      q: this.query,
      per_page: this.perPage,
      preset: this.config.search_options?.preset,
      highlight_full_fields: this.config.highlight_full_fields.join(),
      highlight_affix_num_tokens: 2000,
      highlight_start_tag: '<mark class="px-1 py-1 text-inherit bg-transparent underline underline-offset-4 decoration-attention box-decoration-clone">',
      highlight_end_tag: '</mark>',
    }, this.buildFilters());
    return query;
  },
  buildFilters() {
    if (!this.filters.length) return {};
    return {
      filter_by: `type:=[${this.filters.join()}]`
    };
  },
  toggleFilter(filter) {
    if (this.filters.includes(filter)) {
      this.filters = this.filters.filter(value => value !== filter);
    } else {
      this.filters.push(filter);
    }
    this.search();
  },
  loadPage(page) {
    this.performSearch(page);
  },
  formatDate(timestamp) {
    if (!timestamp) return '';
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString('da-DK', { 
      day: 'numeric', 
      month: 'short', 
      year: 'numeric' 
    });
  },
}));
