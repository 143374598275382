import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('chartbeat', (url, headline, sections) => ({
  url: url,
  headline: headline,
  sections: sections,
  queue: [],
  init() {
    if (!window.pSUPERFLY) {
      window.addEventListener('ChartBeatReady', () => {
        this.processQueue();
      });
    }
  },
  track() {
    this.queue.push({
      sections: this.sections,
      authors: "",
      path: this.url,
      title: this.headline
    });
    if (!!window.pSUPERFLY) {
      this.processQueue();
    }
  },
  processQueue() {
    while (this.queue.length > 0) {
      pSUPERFLY.virtualPage(this.queue.shift());
    }
  }
}));
