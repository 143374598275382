import { intervalToDuration } from 'date-fns';

const formatDuration = (dur) => {
    const duration = intervalToDuration({ start: 0, end: dur * 1000 });
    const { hours = 0, minutes = 0, seconds = 0 } = duration;

    const formattedSeconds = seconds.toString().padStart(2, '0');

    if (hours > 0) {
        const formattedMinutes = minutes.toString().padStart(2, '0');
        return `${hours}:${formattedMinutes}:${formattedSeconds}`;
    }

    if (minutes < 10) {
        return `${minutes}:${formattedSeconds}`;
    }

    return `${minutes}:${formattedSeconds}`;
};

const calcDuration = () => {
    Array.from(document.querySelectorAll('div[data-duration]')).forEach((element) => {
        element.innerText = formatDuration(element.getAttribute('data-duration'));
    });
};

window.duration = formatDuration;

export default calcDuration;
